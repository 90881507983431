@import 'codemirror/lib/codemirror.css';

.CodeMirror {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0;
}
.cm-header {
  font-weight: unset;
}
.CodeMirror .cm-header {
  font-weight: bold;
}